<!-- eslint-disable vue/valid-v-slot -->
<template>
  <Layout>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="teal lighten-1 float-right"
          depressed
          dark
          @click="modalOpen = true"
        >
          <v-icon>mdi-file-plus</v-icon>
          <span class="white--text ml-1">New document</span>
        </v-btn>
        <CreateDocument
          :modal-open="modalOpen"
          @closeModal="closeModal"
          @getAllDocuments="getAllDocuments"
        />
      </v-col>
      <v-col cols="12">
        <v-card class="elevation-0" outlined>
          <v-data-table
            :headers="headers"
            :items="documents"
            class="elevation-0"
            :custom-filter="filter"
            :loading="loading"
            loading-text="Loading documents..."
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Documents</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <div class="flex-grow-1"></div>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <div class="flex-grow-1"></div>
              </v-toolbar>
            </template>
            <template v-slot:item.type="{ item }">
              {{ documentType(item) }}
            </template>
            <template v-slot:item.expiryDate="{ item }">
              {{ expiryDate(item) }}
            </template>
            <template v-slot:item.documentNumber="{ item }">
              {{ documentNumber(item) }}
            </template>

            <template v-slot:item.action="{ item }">
              <v-tooltip v-if="acceptActionShow(item)" top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="clickAccept(item)">
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </template>
                <span>Accept document</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="downloadItem(item)">
                    <v-icon>mdi-cloud-download-outline</v-icon>
                  </v-btn>
                </template>
                <span>Download document</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="deleteItem(item)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete document</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </Layout>
</template>
<script>
import CreateDocument from '@components/Documents/CreateDocument.vue'
import Layout from '@layouts/main'
import { mapGetters } from 'vuex'
import EventBus from '@utils/EventBus'
import api from '@src/api/index.js'
import { format } from 'date-fns'
import { EventBusEvents, documentTypes } from '@src/constants/index.js'

export default {
  name: 'Documents',
  components: {
    CreateDocument,
    Layout,
  },
  data: () => ({
    files: [],
    loading: false,
    documents: [],
    headers: [
      {
        text: 'Client',
        align: 'left',
        value: 'name',
      },
      {
        text: 'Document Type',
        value: 'type',
        align: 'center',
      },
      {
        text: 'Document Number',
        value: 'documentNumber',
        align: 'center',
      },
      {
        text: 'Expiry Date',
        value: 'expiryDate',
        align: 'center',
      },
      {
        text: 'Actions',
        value: 'action',
        sortable: false,
        align: 'center',
      },
    ],
    modalOpen: false,
    search: '',
  }),
  computed: {
    ...mapGetters('user', ['isAdminUser']),
  },
  created() {
    this.getAllDocuments()
  },
  methods: {
    filter(value, search, item) {
      const {
        documentNumber,
        individualName,
        corporateName,
        shareholderName,
        shareholderCompanyName,
        type,
      } = item

      const name =
        individualName ||
        corporateName ||
        shareholderName ||
        shareholderCompanyName

      return (
        name.toLowerCase().includes(search.toLowerCase()) ||
        documentTypes[type].toLowerCase().includes(search.toLowerCase()) ||
        documentNumber.toLowerCase().includes(search.toLowerCase())
      )
    },
    closeModal() {
      this.modalOpen = false
    },
    clickAccept({ documentId }) {
      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        title: 'Accept document',
        message: 'Are you sure to accept this document?',
        confirmAction: () => this.acceptDocument(documentId),
      })
    },
    expiryDate({ expiryDate }) {
      if (expiryDate) {
        return format(new Date(expiryDate), 'dd MMM yyyy')
      }
      return 'n/a'
    },
    documentNumber({ documentNumber }) {
      return documentNumber || 'n/a'
    },
    acceptActionShow({ pending }) {
      return this.isAdminUser && pending
    },
    name(document) {
      const {
        individualName,
        corporateName,
        shareholderName,
        shareholderCompanyName,
      } = document
      return (
        individualName ||
        corporateName ||
        shareholderName ||
        shareholderCompanyName
      )
    },
    documentType({ type }) {
      return documentTypes[type]
    },
    forceFileDownload(blob, fileName) {
      const a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob)
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    async downloadItem({ documentId }) {
      this.loading = true
      try {
        const { data, fileName } = await api.getFileDownload(documentId)
        this.forceFileDownload(data, fileName)
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    deleteItem({ documentId }) {
      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        title: 'Delete document',
        message: 'Are you sure to delete this document?',
        confirmAction: () => this.deleteDocument(documentId),
      })
    },
    async getAllDocuments() {
      this.loading = true
      try {
        const documents = await api.getAllDocuments()
        documents.forEach(element => {
          if(element) {
            element.name= this.name(element);
          }
        });
        this.documents = documents
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async acceptDocument(documentId) {
      this.loading = true
      try {
        await api.acceptDocument(documentId)
        this.getAllDocuments()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async deleteDocument(documentId) {
      this.loading = true
      try {
        await api.deleteDocument(documentId)
        this.getAllDocuments()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
