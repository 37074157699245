import {
  ClientTypesFull,
  documentTypes,
  IndividualClientFields,
  CorporateClientFields,
  IndividualShareholderFields,
  CorporateShareholderFields,
} from '@src/constants/index.js'

export const documentFromClientTypes = ({ clientType, shareholderType }) => {
  const allowedDocuments = {
    individual: IndividualClientFields,
    corporate: CorporateClientFields,
    shareholder: {
      individual: IndividualShareholderFields,
      corporate: CorporateShareholderFields,
    },
  }

  const types =
    clientType === ClientTypesFull.SHAREHOLDER
      ? allowedDocuments[clientType][shareholderType]
      : allowedDocuments[clientType]

  return types.map((type) => ({
    type,
    label: documentTypes[type],
  }))
}
