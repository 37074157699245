var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"teal lighten-1 float-right","depressed":"","dark":""},on:{"click":function($event){_vm.modalOpen = true}}},[_c('v-icon',[_vm._v("mdi-file-plus")]),_c('span',{staticClass:"white--text ml-1"},[_vm._v("New document")])],1),_c('CreateDocument',{attrs:{"modal-open":_vm.modalOpen},on:{"closeModal":_vm.closeModal,"getAllDocuments":_vm.getAllDocuments}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-0",attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.documents,"custom-filter":_vm.filter,"loading":_vm.loading,"loading-text":"Loading documents...","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Documents")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"flex-grow-1"})],1)]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.documentType(item))+" ")]}},{key:"item.expiryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.expiryDate(item))+" ")]}},{key:"item.documentNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.documentNumber(item))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.acceptActionShow(item))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.clickAccept(item)}}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Accept document")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.downloadItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-cloud-download-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Download document")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete document")])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }