<template>
  <v-dialog v-model="modalOpen" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">New Document</span>
      </v-card-title>
      <v-card-text>
        <v-form
          id="documentForm"
          ref="createDocumentForm"
          lazy-validation
          @submit.prevent="checkSubmit"
        >
          <SelfBuildingSpinner v-if="loading" />
          <v-container v-else>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  v-model="clientId"
                  color="red darken-4"
                  outlined
                  :items="clients"
                  :search-input.sync="search"
                  dense
                  :loading="clientLoading"
                  hide-no-data
                  :error-messages="requiredError($v.clientId, 'Client Name')"
                  item-text="name"
                  item-value="clientId"
                  label="Client"
                  return-object
                  @change="autocompleteChange"
                  @blur="$v.clientId.$touch"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="documentType"
                  :items="documentTypes"
                  outlined
                  dense
                  item-text="label"
                  item-value="type"
                  color="red darken-4"
                  :error-messages="
                    requiredError($v.documentType, 'Document type')
                  "
                  label="Document Type"
                  @change="$v.documentType.$touch"
                  @blur="$v.documentType.$touch"
                ></v-select>
              </v-col>
              <v-col v-if="documentType === 'passport'" cols="6">
                <v-text-field
                  v-model="documentNumber"
                  outlined
                  dense
                  :error-messages="
                    requiredError($v.documentNumber, 'Document Number')
                  "
                  color="red darken-4"
                  label="Document Number"
                  @change="$v.documentNumber.$touch"
                  @blur="$v.documentNumber.$touch"
                ></v-text-field>
              </v-col>
              <v-col v-if="documentType === 'passport'" cols="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="expiryDate"
                      outlined
                      dense
                      color="red darken-4"
                      label="Expiry Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      :error-messages="
                        requiredError($v.expiryDate, 'Expiry date')
                      "
                      v-on="on"
                      @change="$v.expiryDate.$touch"
                      @blur="$v.expiryDate.$touch"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="expiryDate"
                    color="red darken-4"
                    no-title
                    scrollable
                  >
                    <div class="flex-grow-1"></div>
                    <v-btn text color="red darken-4" @click="menu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="red darken-4"
                      :disabled="loading"
                      @click="$refs.menu.save(date)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-col
                  cols="12"
                  class="col-12-drag-drop-doc d-flex justify-content-center"
                >
                  <img :src="image" alt="" class="image-class-doc" cols="12" />
                  <vue-dropzone
                    class="drag-drop-image-doc"
                    cols="12"
                    ref="dropzone"
                    id="drop1"
                    :options="dropOptions"
                    @vdropzone-complete="afterComplete"
                    :include-styling="false"
                    :useCustomSlot="true"
                  ></vue-dropzone>
                </v-col>
              </v-col>
              <v-col v-if="documentType === 'other'" cols="12">
                <v-textarea
                  v-model="otherComment"
                  outlined
                  dense
                  color="red darken-4"
                  auto-grow
                  :error-messages="requiredError($v.otherComment, 'Comment')"
                  label="Comment"
                  @change="$v.otherComment.$touch"
                  @blur="$v.otherComment.$touch"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="red darken-4" text @click="reset">Cancel</v-btn>
        <v-btn color="green darken-4" text type="submit" form="documentForm"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import api from '@src/api/index.js'
import { validateError, validExtension } from '@utils/validateFields'
import EventBus from '@utils/EventBus'
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'
import { documentFromClientTypes } from '@utils/documentFromClientTypes'
import {
  documentsIndividual,
  documentsCorporate,
  EventBusEvents,
} from '@src/constants/index.js'
import vueDropzone from 'vue2-dropzone'
const imageURL = require('../../assets/cloud.png')

const getTemplate = () => `
<div class="dz-preview dz-file-preview">
  <div class="dz-image">
    <div data-dz-thumbnail-bg></div>
  </div>
  <div class="dz-details">
    <div class="dz-size"><span data-dz-size></span></div>
    <div class="dz-filename"><span data-dz-name></span></div>
  </div>
  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  <div class="dz-error-message"><span data-dz-errormessage></span></div>
  <div class="dz-success-mark"><i class="fa fa-check"></i></div>
  <div class="dz-error-mark"><i class="fa fa-close"></i></div>
</div>
`

export default {
  name: 'CreateDocument',
  components: {
    SelfBuildingSpinner,
    vueDropzone,
  },
  mixins: [validationMixin],
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
  },
  validations() {
    return {
      documentType: {
        required,
      },
      documentNumber: {
        required: requiredIf(() => this.documentType === 'passport'),
      },
      clientId: {
        required,
      },
      expiryDate: {
        required: requiredIf(() => this.documentType === 'passport'),
      },
      file: {
        required,
        validExtension,
      },
      otherComment: {
        required: requiredIf(() => this.documentType === 'other'),
      },
    }
  },
  data() {
    return {
      clientId: '',
      shareholderType: '',
      clientType: '',
      clients: [],
      date: null,
      clientLoading: false,
      loading: false,
      otherComment: '',
      documentType: null,
      documentNumber: '',
      search: '',
      expiryDate: null,
      file: null,
      fileName: '',
      menu: false,
      dropOptions: {
        url: 'https://httpbin.org/post',
        maxFilesize: 2, // MB
        maxFiles: 20,
        chunking: false,
        chunkSize: 500, // Bytes
        thumbnailWidth: 75, // px
        thumbnailHeight: 75,
        addRemoveLinks: true,
        previewTemplate: getTemplate(),
      },
      image: imageURL,
    }
  },
  computed: {
    documentTypes() {
      if (!this.clientType) {
        return [...documentsIndividual, ...documentsCorporate]
      }
      return documentFromClientTypes({
        clientType: this.clientType,
        shareholderType: this.shareholderType,
      })
    },
  },
  watch: {
    search(val) {
      if (this.clientLoading) return
      if (typeof val === 'string' && val.length > 1) {
        this.fetchClients({ name: val })
      }
    },
    documentType(newVal) {
      if (newVal !== 'passport') {
        this.expiryDate = ''
        this.documentNumber = ''
      }
    },
  },
  methods: {
    autocompleteChange({ clientId, name, clientType, shareholderType }) {
      this.$v.clientId.$touch()

      // reset previous document type
      this.documentType = ''
      this.clientId = clientId
      this.clientType = clientType
      this.shareholderType = shareholderType
      this.name = name
    },
    async fetchClients({ name }) {
      this.clientLoading = true
      try {
        const clients = await api.getClientsByName({ name, shareholders: true })
        this.clients = clients
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.clientLoading = false
      }
    },
    fileErrors(file) {
      return validateError(
        file,
        ['required', 'validExtension'],
        ['File is required', 'Extension wrong, accepted: png, jpg, pdf']
      )
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
    createDocument() {
      const formData = new FormData()
      formData.append('clientId', this.clientId)
      formData.append('name', this.name)
      formData.append('documentType', this.documentType)
      formData.append('comment', this.otherComment)
      formData.append('document', this.file)

      if (this.documentNumber)
        formData.append('documentNumber', this.documentNumber)
      if (this.expiryDate) formData.append('expiryDate', this.expiryDate)

      this.postDocument(formData)
    },

    async postDocument(formData) {
      this.loading = true
      try {
        await api.createDocument(formData)
        this.loading = false
        setTimeout(() => this.reset(), 0)
        this.$emit('getAllDocuments')
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    reset() {
      this.$refs.createDocumentForm.reset()
      this.$v.$reset()
      this.$refs.dropzone.removeAllFiles()
      this.$emit('closeModal')
    },
    checkSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.createDocument()
      }
    },
    afterComplete(file) {
      this.file = file
    },
  },
}
</script>

<style>
.col-12-drag-drop-doc {
  border: darkgrey 3px dotted;
  height: auto;
  justify-items: center;
  border-radius: 20px;
}
.drag-drop-image-doc {
  padding-top: 50px;
  margin-left: -100px;
  padding-bottom: 2px;
}
.image-class-doc {
  padding: 0px 5px 5px 210px;
  height: 50px;
}
</style>
